var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-input",
    {
      attrs: { label: "Указать адрес", value: _vm.defaultValue },
      model: {
        value: _vm.fullStreet,
        callback: function($$v) {
          _vm.fullStreet = $$v
        },
        expression: "fullStreet"
      }
    },
    [
      _vm.isVisibleList
        ? _c(
            "div",
            { staticClass: "autocomplete__prompt-list" },
            _vm._l(_vm.daData.loadedAddresses, function(loadedAddress) {
              return _c(
                "div",
                {
                  staticClass: "autocomplete__prompt-item",
                  on: {
                    click: function($event) {
                      return _vm.setLoadedAddressInCustomer(
                        loadedAddress.data,
                        false
                      )
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(loadedAddress.data.street_with_type) +
                      "\n      " +
                      _vm._s(loadedAddress.data.house_type_full) +
                      "\n      " +
                      _vm._s(loadedAddress.data.house) +
                      "\n    "
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "autocomplete__prompt-message" }, [
        _vm._v(_vm._s(_vm.message))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-24fd54cc", { render: render, staticRenderFns: staticRenderFns })
  }
}