<template>
  <init-modal
    class="address-modal"
    :is-show="isShow"
    :is-bottom-position="screenWidth <= 768"
    @close="closeModal"
  >
    <div class="address-modal__content">
      <h1 class="address-modal__title">
        Указать адрес
      </h1>
      <div class="address-modal__radio">
        <app-radio
          v-for="(radio, index) in deliveryTypeRadioList"
          :key="++index"
          :radio-data="radio"
          @change="changeDeliveryType"
        />
      </div>
      <div
        v-show="activeDeliveryType === DELIVERY_TYPES.COURIER"
        class="address-modal__input-button"
      >
        <app-autocomplete :default-value="defaultInputValue" />
        <app-button
          :is-disabled="isDisabledButton"
          @click="chooseAddressCourier"
        >
          Доставить сюда
        </app-button>
      </div>
      <div
        v-show="activeDeliveryType === DELIVERY_TYPES.PICKUP"
        class="address-modal__input-button"
      >
        <app-select
          label="Пункты самовывоза"
          empty-field-text="Указать адрес самовывоза"
          :options="pickupPoints"
          :default-selected-option="defaultSelectedOption"
          @change="selectOption"
        />
        <app-button @click="chooseAddressPickup">
          Заберу отсюда
        </app-button>
      </div>
      <div class="address-modal__map">
        <iframe
          v-show="activeDeliveryType === DELIVERY_TYPES.PICKUP"
          id="pickup-iframe-map-modal"
          :src="pickupPointsMapUrl"
          width="100%"
          height="100%"
          scrolling="no"
        >
          Функционал выбора точки на карте не поддерживается в текущем браузере :(
        </iframe>
        <iframe
          v-show="activeDeliveryType === DELIVERY_TYPES.COURIER"
          :src="deliveryRegionsMapUrl"
          width="100%"
          height="100%"
          scrolling="no"
        >
          Функционал выбора точки на карте не поддерживается в текущем браузере :(
        </iframe>
      </div>
    </div>
  </init-modal>
</template>

<script>
import appRadio from '@ui/app-radio/appRadio.vue'
import appButton from '@ui/app-button/appButton.vue'
import appSelect from '@ui/app-select/appSelect.vue'
import initModal from '@ui/app-modals/init-modal/initModal.vue'
import AppAutocomplete from '@ui/app-autocomplete/appAutocomplete.vue'

import store from '@store'
import mutations from '@store/mutations'
import * as actions from '@store/types/actions-types'
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex'
import { DELIVERY_TYPES } from '@/constants'

export default {
  name: 'AddressModal',
  components: {
    AppAutocomplete,
    appButton,
    appSelect,
    initModal,
    appRadio,
  },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    pickupPointsMapUrl: {
      type: String,
    },
    deliveryRegionsMapUrl: {
      type: String,
    },
    deliveryTypeRadioList: {
      type: Array,
    },
    daData: {
      type: Object,
      required: true,
    },
    activeDeliveryType: {
      type: String,
      default: DELIVERY_TYPES.COURIER,
    },
    activeDelivery: {
      type: Object,
    },
  },
  emits: ['close', 'change-select', 'set-order-delivery-type'],
  methods: {
    ...mapActions({
      chooseAddressCourier: actions.CHOOSE_ADDRESS_COURIER,
    }),
    ...mapMutations({
      updateActivePickupPoint: mutations.UPDATE_PICKUP_POINTS_SELECTED,
    }),
    closeModal() {
      this.$emit('close')
    },
    changeDeliveryType(event) {
      this.$emit('set-order-delivery-type', event)
    },
    selectOption(event) {
      this.$emit('change-select', event)
    },
    chooseAddressPickup() {
      this.updateActivePickupPoint(this.activeDelivery)
      store.dispatch(actions.CHOOSE_ADDRESS_PICKUP, false)
    },
  },
  computed: {
    ...mapState({
      pickupPoints: state => state.pickupPoints,
      screenWidth: state => state.screenWidth,
      customer: state => state.customer,
    }),
    ...mapGetters({
      deliveryPriceLocal: 'calculateDeliveryPriceLocal',
      isNullDeliveryPrice: 'isNullDeliveryPricesTest',
    }),
    DELIVERY_TYPES() {
      return DELIVERY_TYPES
    },
    defaultSelectedOption() {
      return this.customer.delivery.pickupPoint !== null
        ? this.customer.delivery.pickupPoint['option']
        : {}
    },
    defaultInputValue() {
      return this.customer.address.fullStreet !== null
        ? this.customer.address.fullStreet
        : ''
    },
    isCorrectAddress() {
      return this.daData.address.house.length > 0 && this.daData.address.street.length > 0
    },
    isDisabledButton() {
      return this.isNullDeliveryPrice
          || !this.isCorrectAddress
          || !!this.deliveryPriceLocal.remainingAmount
    },
  },
}
</script>
