import Vue from 'vue'
import { TOAST_DURATION } from '../constants/index'
import { translate } from './translator'

export const showModal = key => {
    Vue.toasted.show(
        `Пожалуйста, укажите ${translate(key)}`,
        { duration: TOAST_DURATION },
    )
}
