<template>
  <app-input
    v-model="fullStreet"
    label="Указать адрес"
    :value="defaultValue"
  >
    <div
      v-if="isVisibleList"
      class="autocomplete__prompt-list"
      v-cloak
    >
      <div
        class="autocomplete__prompt-item"
        v-for="loadedAddress in daData.loadedAddresses"
        @click="setLoadedAddressInCustomer(loadedAddress.data, false)"
      >
        {{ loadedAddress.data.street_with_type }}
        {{ loadedAddress.data.house_type_full }}
        {{ loadedAddress.data.house }}
      </div>
    </div>
    <span class="autocomplete__prompt-message">{{ message }}</span>
  </app-input>
</template>

<script>
import appInput from '../app-input/appInput.vue'
import fullStreetTemp from '../../mixins/fullStreetTemp'

import { mapState } from 'vuex'

export default {
  name: 'appAutocomplete',
  components: {
    appInput
  },
  mixins: [fullStreetTemp],
  emits: ['click'],
  props: {
    defaultValue: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapState({
      daData: state => state.daData,
      message: state => state.daData.message
    }),
    isVisibleList() {
      return this.daData.loadedAddresses && this.daData.loadedAddresses.length > 0 && this.fullStreet.trim()
    },
  },
}
</script>
