const PROMO_CODE_TYPE_ABSOLUTE_VALUE = 'absoluteValue';
const PROMO_CODE_TYPE_PERCENT = 'percent';

// TODO: из-за того что доставка возможно от определенного лимита, если сумма < лимита по умолчанию
// возвращается null и ломается totalSum. т.к. любое число + null = NaN. => нужно починить
export const totalSum = (state) => bonuses =>
    (basketSum(state) + calculateDeliveryPrice(state) - discountOfBasket(state)(bonuses)) > 0
        ? (basketSum(state) + calculateDeliveryPrice(state) - discountOfBasket(state)(bonuses))
        : 0

export const discountOfBasket = state => bonuses => +bonuses + +promoCodeCalculator(state)

export const draftQuantity = state => id => {
    const product = state.draftProducts.find(p => p.id === id)

    return product ? product.quantity : 1
}

export const draftSum = state => (id, defaultPrice) => {
    const product = state.draftProducts.find(p => p.id === id)

    return product ? (product.price * product.quantity) : defaultPrice
}

export const discriminatedDraftQuantity = state => (id, discriminator) => {
    const product = state.draftProducts.find(p => p.id === id)

    if (product) {
        const discriminated = product.discriminated.find(d => d.discriminator === discriminator)

        if (discriminated) {
            return discriminated.quantity
        }
    }

    return 1
}

/**
 * Функция для вычисления стоимости доставки.
 * @param {Object} state - Объект состояния.
 * @param {Object} deliveryPrices - Объект, содержащий цены на доставку.
 * @returns {number|Object} - Стоимость доставки (число) или объект с минимальной стоимостью доставки и оставшейся
 *                            суммой для бесплатной доставки.
 *
 * Примечание: При оформлении заказа функция всегда должна возвращать число.
 * Если возвращается объект, при выборе адреса кнопка блокируется, пока не будет набрана минимальная сумма заказа.
 */
const calculatePrice = (state, deliveryPrices) => {
    const thresholdForDeliveryPrice = Object.keys(deliveryPrices)
        .sort((a, b) => b - a)
        .find(minPrice => basketSum(state) >= minPrice)

    if (thresholdForDeliveryPrice) {
        return deliveryPrices[thresholdForDeliveryPrice]
    }

    const minDeliveryPrice = Math.min(...Object.keys(deliveryPrices))
    const remainingAmount = Math.max(0, minDeliveryPrice - basketSum(state))

    return {
        minDeliveryPrice,
        remainingAmount
    }
}

export const calculateDeliveryPrice = (state) =>
    state.daData.deliveryPrices ? calculatePrice(state, state.daData.deliveryPrices) : 0

export const calculateDeliveryPriceLocal = (state) =>
    state.daData.deliveryPricesTest ? calculatePrice(state, state.daData.deliveryPricesTest) : null

export const calculateBonusesAvailableToUse = (state) => {
    if (state.bonusSystem) {
        return state.bonusSystem.bonusesLimitPerPayment * basketSum(state) / 100
    }
}

export const isNullDeliveryPricesTest = (state) =>
    state.daData.deliveryPricesTest === null

export const calculateRewardBonuses = (state) => {
    const productsSumValue = basketSum(state)

    if (state.bonusSystem) {
        return Math.round(state.bonusSystem.bonusRewardPercent * productsSumValue / 100)
    }
}

export const isCustomerHaveBonuses = (getters) => {
    return getters.clientBonuses > 0
}

export const maxAmountBonusesToUse = (state, getters) => {
    return getters.calculateBonusesAvailableToUse >= getters.clientBonuses
        ? getters.clientBonuses
        : getters.calculateBonusesAvailableToUse
}

export const clientBonuses = state => {
    return state.authenticatorData.client.bonuses
}

export const promoCodeCalculator = (state) => {
    const basketSumValue = basketSum(state)
    if (state.promoCode.promoCodeFromServer) {
        switch (state.promoCode.promoCodeFromServer.discountType) {
            case PROMO_CODE_TYPE_PERCENT:
                return basketSumValue * state.promoCode.promoCodeFromServer.discount / 100;
            case PROMO_CODE_TYPE_ABSOLUTE_VALUE:
                return state.promoCode.promoCodeFromServer.discount;
            default:
                return null;
        }
    }

    return 0
}

export const discriminatedDraftTotalSum = state => (id, defaultPrice, discriminator) => {
    const product = state.draftProducts.find(p => p.id === id)
    const discriminated = product
    && product.discriminated.find(d => d.discriminator === discriminator)

    return discriminated
        ? discriminated.quantity * product.price
        : defaultPrice
}

export const availableParameters = state => (productId, discriminator) => {
    /* eslint-disable no-nested-ternary */
    const availableParametersLoc = state.availableParameters
        .map(parameter => ({ // map all parameter to sorted parameters
            key: parameter.key,
            values: parameter.values
                .filter(p => String(p) === '0' || !!p) // remove all falsy values except '0' and 0
                .sort((a, b) => ((Number.isNaN(a) || Number.isNaN(b))
                    ? (a > b ? 1 : -1)
                    : (Number(a) > Number(b) ? 1 : -1))),
        }))

    const draft = state.draftProducts.find(p => p.id === productId)

    const additionalParameters = draft
        ? discriminator
            ? (
                draft.discriminated.find(d => d.discriminator === discriminator)
                || { additionalParameters: [] }
            ).additionalParameters || []
            : (draft || { additionalParameters: [] }).additionalParameters || []
        : []
    /* eslint-enable no-nested-ternary */

    return [
        ...availableParametersLoc
            .filter(parameter => !additionalParameters.map(p => p.key).includes(parameter.key)),
        ...additionalParameters,
    ]
}

export const isAuthorizedClient = state => state.authenticatorData.client?.isAuthenticated

/* BASKET LOGIC start */
export const idListFromBasketWithoutModifiers = state => state.basket.map(
    v => (v.modifiersIdList.length === 0 ? +v.id : null),
)

// eslint-disable-next-line max-len
export const quantityOfProductVariant = state => variantId => state.basket.find(v => v.id === +variantId).quantity

export const findVariantInBasket = state => variant => {
    // поиск варианта в корзине по списку id модификаторов и id варианта
    // eslint-disable-next-line max-len
    return state.basket.find(item => JSON.stringify(item.modifiersIdList) === JSON.stringify(variant.modifiersIdList)
            && item.id === variant.id)
}

export const basketSum = state => state.basket.reduce(
    (acc, item) => acc + (item.variantTotalSum * item.quantity), 0)

export const basketTotalQuantity = state => state.basket.reduce((acc, item) => acc + item.quantity, 0)
/* BASKET LOGIC end */

export const isCourierPayment = state => (state.customer.paymentType === 'courier_cash' || state.customer.paymentType === 'courier_card')
